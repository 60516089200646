<template>
  <div class="row justify-content-center">
    <customer-header />
    <vs-prompt
      color="success"
      title=""
      accept-text="Export Excel"
      cancel-text="ยกเลิก"
      @accept="exportExcelReport"
      @close="close"
      :active.sync="activePromptExport"
    >
      <div class="con-exemple-prompt">
        Export Report Excel
        <br /><br />
        <datepicker
          placeholder="วันที่เริ่มต้น เวลาเข้า"
          :format="DatePickerFormat"
          v-model="startDateReport"
          :click="onClickDate()"
        ></datepicker>
        <br />
        <datepicker
          placeholder="วันที่สิ้นสุด เวลาเข้า"
          :format="DatePickerFormat"
          v-if="startDateReport"
          v-model="endDateReport"
          :disabledDates="disabledDates"
        ></datepicker>
        <br />
        <p style="color: red" align="center">
          Export ได้มากที่สุดแค่ 31 วัน จากวันเริ่มต้น
        </p>
      </div>
    </vs-prompt>

    <vs-prompt
      color="primary"
      title=""
      accept-text="ค้นหา"
      cancel-text="ยกเลิก"
      @accept="listItems(1)"
      @close="close"
      :active.sync="activePromptSearch"
    >
      <div class="con-exemple-prompt">
        ค้นหาข้อมูลรถเข้า-ออก

        <br /><br />
        <v-select
          label="text"
          placeholder="กรุณาเลือก"
          v-model="selected"
          @input="checkOption"
          :options="optionsLists"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />

        <vs-input
          v-if="inputSearch === 1"
          type="text"
          icon="search"
          placeholder="คำค้นหา"
          v-model="searchText"
        />
        <vs-input
          v-if="inputSearch === 2"
          type="date"
          icon="event"
          v-model="searchText"
        />
      </div>
    </vs-prompt>

    <vs-prompt
      color="primary"
      title=""
      accept-text="ตกลง"
      cancel-text="ยกเลิก"
      @accept="checkPwd()"
      @close="close"
      :active.sync="activePromptLogin"
    >
      <div class="con-exemple-prompt">
        เพื่อความปลอดภัยของข้อมูล กรุณากรอกรหัสผ่าน

        <br /><br />
        <vs-input type="password" placeholder="รหัสผ่าน" v-model="password" />
      </div>
    </vs-prompt>

    <vs-popup
      class="holamundo"
      title="รายละเอียดข้อมูลรถเข้า-ออก"
      :active.sync="activePromptDetail"
      v-if="objectDetail"
    >
      <div class="vx-row">
        <div class="vx-col lg:w-1/1 w-full">
          <vx-card class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">เวลาเข้า</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.date_in | formatDate }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">เวลาออก</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.date_out | formatDate }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">ประเภทรถ</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.car_type | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">เลขที่ติดต่อ</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.contact_address_name | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">ทะเบียนรถ</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.license_plate_id | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">ตราประทับ</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.stamp_type | checkString }}
                </td>
              </tr>
              <tr v-if="customer.enable_fee === true">
                <td class="font-semibold">ค่าจอดรวม</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.sum_amount | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">เวลาจอด</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.date_full | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">บัตรหาย</td>
                <td
                  style="padding-left: 50px"
                  v-if="objectDetail.date_out !== null"
                >
                  {{ objectDetail.card_missing | cardMissing }}
                </td>
                <td style="padding-left: 50px" v-else>-</td>
              </tr>
              <tr>
                <td class="font-semibold">วัตถุประสงค์</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.visit_objective_type | checkString }}
                </td>
              </tr>

              <tr>
                <td class="font-semibold">ชื่อรูปที่บันทึก</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.id_card_photo | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">หมายเลขเข้า</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.ticket_no | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">แจ้งปิดบ้าน</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.is_close_home | convertCloseHome }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">เหตุผลปิดบ้าน</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.close_home_reason | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">แขกวีไอพี</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.is_vip | convertVip }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">หมายเลขใบเสร็จ</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.bill_no | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">ใช้สิทธิ์</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.use_coupon | checkString }}
                </td>
              </tr>

              <tr :key="indextr" v-for="(field, indextr) in additionFields">
                <td class="font-semibold">{{ field.report_field }}</td>
                <td style="padding-left: 50px">
                  <div
                    style="
                      border: 1px solid #3f4652;
                      padding: 6px;
                      background-color: #6b7585;
                      margin-top: 10px;
                    "
                    v-if="Array.isArray(objectDetail[field.valueField])"
                  >
                    <div
                      style="margin-top: 6px"
                      :key="indextr_image"
                      v-for="(image, indextr_image) in objectDetail[
                        field.valueField
                      ]"
                    >
                      <img
                        v-img
                        style="width: 120px"
                        :src="image"
                        class="img-fluid"
                        :key="indextr_image"
                      />
                    </div>
                  </div>
                  <span v-if="!Array.isArray(objectDetail[field.valueField])">
                    {{ objectDetail[field.valueField] | checkString }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">id</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.key | checkString }}
                </td>
              </tr>
            </table>
          </vx-card>
        </div>
      </div>
    </vs-popup>

    <vs-list>
      <vs-list-item class="k-nav-bar" title="ข้อมูลบัตรผ่าน" subtitle="">
        <div class="demo-inline-spacing">
          <vs-button
            v-if="searchText"
            type="line"
            line-origin="left"
            color="success"
            disabled
            ><h4>คำค้นหา >> {{ searchText }}</h4></vs-button
          >
          <vs-button
            v-if="searchText"
            @click="resetSearch"
            color="dark"
            type="filled"
            icon-pack="feather"
            style="margin-left: 10px"
          >
            Reset
          </vs-button>
          <!-- <vs-button
            @click="openAddSearch"
            color="primary"
            type="filled"
            icon-pack="feather"
            icon="icon-search"
            style="margin-left: 5px"
          >
            ค้นหา
          </vs-button>
          <vs-button
            :loading="downloadLoading"
            @click="openModalExcel"
            color="success"
            type="filled"
            style="margin-left: 5px"
          >
            <feather-icon
              icon="DownloadIcon"
              title="Export Excel"
              svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
            />
            Export
          </vs-button> -->
        </div>
      </vs-list-item>
    </vs-list>
    <vs-card style="margin-top: 10px">
      <div>
        <vs-table stripe :data="items">
          <template slot="thead" style="margin-top: 100px">
            <vs-th>เวลาสร้าง</vs-th>
            <vs-th>เวลาสิ้นสุด</vs-th>
            <!-- <vs-th>เวลาเข้า</vs-th> -->
            <!-- <vs-th>เวลาออก</vs-th> -->
            <vs-th>ประเภทรถ</vs-th>
            <vs-th>เลขที่ติดต่อ</vs-th>
            <vs-th>ทะเบียนรถ</vs-th>
            <!-- <vs-th>ตราประทับ</vs-th> -->
            <!-- <vs-th v-if="customer.enable_fee === true">ค่าจอดรวม</vs-th> -->
            <!-- <vs-th>เวลาจอด</vs-th> -->
            <!-- <vs-th>บัตรหาย</vs-th> -->
            <vs-th>วัตถุประสงค์</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].ticket_pass_create_date">
                {{ data[indextr].ticket_pass_create_date | formatDate }}
              </vs-td>
              <vs-td :data="data[indextr].ticket_pass_expire_date">
                {{ data[indextr].ticket_pass_expire_date | formatDate }}
              </vs-td>
              <vs-td :data="data[indextr].car_type">
                {{ data[indextr].car_type | checkString }}
              </vs-td>
              <vs-td :data="data[indextr].contact_address_name">
                {{ data[indextr].contact_address_name | checkString }}
              </vs-td>
              <vs-td :data="data[indextr].license_plate_id">
                {{ data[indextr].license_plate_id | checkString }}
              </vs-td>
              <vs-td :data="data[indextr].visit_objective_type">
                {{ data[indextr].visit_objective_type | checkString }}
              </vs-td>
              <vs-td>
                <feather-icon
                size="250"
                  icon="XCircleIcon"
                  title="ดูข้อมูลเพิ่มเติม"
                  svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                  @click="deleteItem(data[indextr])"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div>
          <br />

          <vs-row>
            <vs-col class="text-right">
              <div class="btn-group">
                <vs-button
                  type="border"
                  style="padding-top: 2px"
                  :disabled="page == 1"
                  @click="previousData"
                  >Prev</vs-button
                >
                <vs-button
                  type="border"
                  :disabled="items.length < show_per_page"
                  @click="nextData"
                  >Next</vs-button
                >
              </div>
            </vs-col>
          </vs-row>

          <!--
          <paginate
            v-model="page"
            :page-count="total"
            :page-range="3"
            :margin-pages="2"
            :click-handler="inputData"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate> -->
        </div>
      </div>
    </vs-card>
    <vs-card style="margin-top: 5px">
      <!-- <h5>จำนวนข้อมูลทั้งหมด {{ totalItems }}</h5> -->
    </vs-card>
  </div>
</template>

<script>
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db, firebaseApp } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import CustomerHeader from "@/layouts/components/custom/CustomerHeader.vue";
console.log("lang", lang);

import Paginate from "vuejs-paginate";
import { functions } from "firebase";
import vSelect from "vue-select";
import VueImg from "v-img";

Vue.component("paginate", Paginate);

Vue.use(VueCryptojs);
Vue.use(VueCookies);

const vueImgConfig = {
  // Use `alt` attribute as gallery slide title
  altAsTitle: false,
  // Display 'download' button near 'close' that opens source image in new tab
  sourceButton: false,
  // Event listener to open gallery will be applied to <img> element
  openOn: "click",
  // Show thumbnails for all groups with more than 1 image
  thumbnails: false,
};

Vue.use(VueImg, vueImgConfig);

Vue.component("customer-header", CustomerHeader);

moment.locale("th");
Vue.use(require("vue-moment"), {
  moment,
});
Vue.filter("formatDate", function (value) {
  if (value) {
    const myDate = new Date(value.seconds * 1000); // date object
    return moment(String(myDate)).format("DD/MM/YYYY HH:mm:ss");
  }
  return "-";
});
Vue.filter("cardMissing", function (value) {
  if (value === false) {
    return "มีบัตร";
  } else {
    return "บัตรหาย";
  }
});
Vue.filter("convertCloseHome", function (value) {
  if (value === true) return "ปิดบ้าน";
  return "-";
});
Vue.filter("convertVip", function (value) {
  if (value === true) return "วีไอพี";
  return "-";
});
Vue.filter("checkString", function (value) {
  if (value) return value;
  return "-";
});
console.log(Vue.moment().locale());
export default {
  name: "ManageTicketTransactions",
  components: {},
  data() {
    return {
      roleInfo: {},
      userInfo: null,
      language: "en",
      languages: lang,
      houseInfo: {},
      items: [],
      // pagination
      total: 0, // จำนวนหน้า
      show_per_page: 10,
      page: 1,
      items2: [],
      // totalItems: 0, // จำนวนข้อมูลทั้งหมด
      isLoad: true,
      // lastItem: null,
      searchText: "",
      password: "",
      activePromptSearch: false,
      activePromptDetail: false,
      activePromptLogin: false,
      selected: "",
      optionsLists: [
        { text: "เลือกวันที่รถเข้า", value: "date_in" },
        { text: "เลขที่ติดต่อ", value: "contact_address_name" },
        { text: "ทะเบียนรถ", value: "license_plate_id" },
      ],
      inputSearch: 0,
      objectDetail: null,
      activePromptExport: false,
      startDateReport: "",
      endDateReport: "",
      DatePickerFormat: "dd/MM/yyyy",
      dateStart: null,
      disabledDates: {},
      itemsReport: [],
      additionFields: [],
      customer: {},
      headerTitle: [
        "เวลาเข้า",
        "เวลาออก",
        "ประเภทรถ",
        "เลขที่ติดต่อ",
        "ทะเบียนรถ",
        "แจ้งปิดบ้าน",
        "เหตุผลปิดบ้าน",
        "ตราประทับ",
        "ค่าจอดรวม",
        "ระยะเวลา",
        "คูปอง",
        "วัตถุประสงค์",
        "หมายเลขเข้า",
        "หมายเลขใบเสร็จ",
        "บัตรหาย",
        "ชื่อรูปที่บันทึก",
        "แขกวีไอพี",
        "id",
      ],
      headerVal: [
        "date_in",
        "date_out",
        "car_type",
        "contact_address_name",
        "license_plate_id",
        "is_close_home",
        "close_home_reason",
        "stamp_type",
        "sum_amount",
        "date_full",
        "use_coupon",
        "visit_objective_type",
        "ticket_no",
        "bill_no",
        "card_missing",
        "id_card_photo",
        "is_vip",
        "key",
      ],
      downloadLoading: false,
      lastItemsList: {},
      limit: 0,
    };
  },
  computed: {
    validName() {
      return true;
    },
  },

  methods: {
    dbRef() {
      const customerName = $cookies.get("customer");
      const _ducumentPath = `backup/${customerName}/transactions`;
      return db.collection(_ducumentPath);
    },

    getDbRefWithCondition() {
      console.log("[[getDbRefWithCondition]]");

      let reference = this.dbRef();


      reference = reference.where(
            "is_ticket_pass",
            "==",
            true
          );

      reference = reference.orderBy("ticket_pass_create_date", "desc");
      // console.log(".orderBy")
      // if (isForCountAll === true) {
      //   return reference;
      // }
      const lastItem = this.lastItemsList[`${this.page - 1}`];

      if (lastItem !== undefined) {
        reference = reference.startAfter(lastItem).limit(this.show_per_page);
        // console.log(".startAt")
      }
      if (this.show_per_page !== null) {
        reference = reference.limit(this.show_per_page);
        // console.log(".limit")
      }
      return reference;
    },

    getDbRefWithConditionAll() {
      console.log("[[getDbRefWithConditionAll11]]");

      const _ducumentPath = "/customer/วิลลาจจิโอ พระราม2/transactions/";

      let reference = db.collection(_ducumentPath);

      // reference = reference.where("date_out",
      //       "==",null
      //     );

      // reference = reference.orderBy("date_in", "desc");
      // console.log(".orderBy")
      // if (isForCountAll === true) {
      //   return reference;
      // }

      return reference;
    },

    async listItems({ page = 1 }) {
      this.isLoad = true;
      this.page = page;
      console.log("PAGE [" + page + "] this page = " + this.page);
      this.getDbRefWithCondition().onSnapshot((snapshotChange) => {
        this.items = [];

        snapshotChange.forEach((doc) => {
          this.lastItemsList[`${page}`] = doc;

          let date_full = "";
          if (doc.data().date_in && doc.data().date_out) {
            date_full = this.strDate(
              doc.data().date_in.seconds,
              doc.data().date_out.seconds
            );
          }

          let element = {
            ticket_pass_create_date: doc.data().ticket_pass_create_date,
            ticket_pass_expire_date: doc.data().ticket_pass_expire_date,
            key: doc.id,
            addition_remark: doc.data().addition_remark,
            // bill_no: doc.data().bill_no,
            car_type: doc.data().car_type,
            // card_missing: doc.data().card_missing,
            // card_mission_amount: doc.data().card_mission_amount,
            // close_home_reason: doc.data().close_home_reason,
            contact_address_name: this.isPrefixAddress(
              doc.data().prefix_address,
              doc.data().contact_address_name
            ),
            // date_in: doc.data().date_in,
            // date_out: doc.data().date_out,
            // date_full: date_full,
            // device_id: doc.data().device_id,
            id_card_photo: doc.data().id_card_photo,
            idcard_photo_name: doc.data().idcard_photo_name,
            // is_close_home: doc.data().is_close_home,
            // is_vip: doc.data().is_vip,
            license_plate_id: doc.data().license_plate_id,
            // seq: doc.data().seq,
            stamp_type: doc.data().stamp_type,
            // sum_amount: doc.data().sum_amount,
            // sum_total_time: doc.data().sum_total_time,
            ticket_no: doc.data().ticket_no,
            // use_coupon: doc.data().use_coupon,
            visit_objective_type: doc.data().visit_objective_type,
            // addition_fields_value: doc.data().addition_fields_value,
          };

          element = this.getAdditionFieldValue(element);

          this.items.push(element);
          this.isLoad = false;
        });
      });
    },

    async listItemsAll() {
      this.isLoad = true;
      console.log("[[listItemsAll]]");

      const end = new Date("2022-01-19");

      db.collection("customer")
        .doc("มณีรินทร์ รัตนาธิเบศร์")
        // .doc("K Visit Demo")
        .collection("transactions")
        // .where("license_plate_id", "==", "3533")
        .where("date_out", "==", null)
        .where("date_in", "<=", end)
        .orderBy("date_in", "desc")
        .get()
        .then((query) => {
          this.items2 = [];
            console.log("listItemsAll this.items2.length="+this.items2.length);

          for (let i = 0; i < query.docs.length; i++) {
            const doc = query.docs[i];
            const data = doc.data();
            const id = doc.ref.id;
            this.items2[this.items2.length] = data;
            console.log("listItemsAll this.items2.length="+this.items2.length);
            console.log("id=", id);
            const myDate = new Date(data.date_in.seconds * 1000); // date object
            const dd = moment(String(myDate)).format("DD/MM/YYYY HH:mm:ss");
            console.log("date_in="+dd );

            data.date_out = new Date();
            data.is_fource_out_by_koder3 = true;

            // db.collection("customer")
        // .doc("วิลลาจจิโอ พระราม2")
        // // .doc("K Visit Demo")
        // .collection("transactions").doc(id).set(data);
            // console.log("listItemsAll items2 l=" + this.items2.length);
            // break;
          }
        });
    },

    isPrefixAddress(prefix_address, contact_address_name) {
      return prefix_address
        ? prefix_address + " / " + contact_address_name
        : contact_address_name;
    },

    isEmpty(data) {
      return data === undefined || data === null || data === "";
    },

    previousData: function () {
      this.page = this.page - 1;
      this.listItems({ page: this.page });
    },

    nextData: function () {
      this.page = this.page + 1;
      this.listItems({ page: this.page });
    },

    inputData: function (value) {
      this.page = value;
      this.listItems({ page: this.page });
    },

    close() {
      this.selected = "";
      this.searchText = "";
      this.inputSearch = 0;
    },

    openAddSearch() {
      this.selected = "";
      this.searchText = "";
      this.inputSearch = 0;
      this.activePromptSearch = true;
    },

    checkOption() {
      this.searchText = "";
      if (this.selected.value === "date_in") {
        this.inputSearch = 2;
      } else {
        this.inputSearch = 1;
      }
    },

    setDateValue(type) {
      const dateStart =
        type === "excel" ? this.startDateReport : this.searchText;
      const dateEnd = type === "excel" ? this.endDateReport : this.searchText;
      const date_formatS = new Date(dateStart);
      const date_formatE = new Date(dateEnd);
      const date_inS = new Date(date_formatS - 60 * 60 * 1000);
      const date_inE = new Date(date_formatE - 60 * 60 * 1000);
      date_inS.setHours(0, 0, 0, 0);
      const value = date_inS;
      date_inE.setDate(date_inE.getDate() + 1);
      date_inE.setHours(0, 0, 0, 0);
      const value2 = date_inE;
      // console.log(
      //   "setDateValue TYPE[" + type + "] s=" + value + " e=[" + value2 + "]"
      // );
      return [value, value2];
    },

    getExportFileName() {
      const customerName = $cookies.get("customer");
      const startDate = moment(new Date(this.startDateReport)).format(
        "DDMMYYYY"
      );
      const endDate = moment(new Date(this.endDateReport)).format("DDMMYYYY");
      return customerName + "-" + startDate + "-" + endDate;
    },

    strDate(begin, end) {
      const d = Math.trunc((end - begin) / 60 / 60 / 24);
      const h = Math.trunc((end - begin) / 60 / 60) % 24;
      const m = Math.trunc((end - begin) / 60) % 60;
      const s = (end - begin) % 60;
      let msg = "";
      if (d > 0) {
        msg += d + " วัน ";
      }
      if (h > 0) {
        msg += h + " ชั่วโมง ";
      }
      if (m > 0) {
        msg += m + " นาที ";
      }
      if (s > 0) {
        msg += s + " วินาที ";
      }
      if (s <= 0) {
        msg += s + " วินาที ";
      }
      // console.log("strDate TIME DAY["+d+"]HOUR["+h+"]minute["+m+"]second["+s+"] = "+msg);
      return msg;
    },

    async resetSearch() {
      this.searchText = "";
      this.items = [];
      this.total = 0;
      this.page = 1;
      this.isLoad = true;
      this.total = 0;
      // this.totalItems = 0;
      // this.lastItem = null;
      this.selected = "";
      this.listItems(1);
    },


    deleteItem(objectData) {

      this.objectDetail = objectData;

        this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'ลบ',
        text: `ต้องการลบใบผ่านนี้`,
        accept: this.deleteRecord,
        acceptText: 'ลบ',
        cancelText:'ยกเลิก'
      });
    },

    deleteRecord(objectData){
  //  event.preventDefault();
      const customerName = $cookies.get("customer");
      db.collection("backup/"+customerName+"/transactions")
        .doc(this.objectDetail.key)
        .update({is_ticket_pass: false, remove_ticket_password_date: new Date()})
        .then(() => {
          console.log("successfully updated!");
          // this.$router.push("/list");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openDetail(objectData) {
      this.objectDetail = null;

      this.activePromptLogin = true;
      this.objectDetail = objectData;
    },

    checkPwd() {
      const pawd = Vue.$cookies.get("pawd");

      console.log("pawd=" + pawd);
      console.log("password=" + this.password);

      if (pawd === this.password) {
        this.activePromptDetail = true;
        this.password = "";
      } else {
        this.$vs.notify({
          color: "danger",
          title: "แจ้งเตือน",
          text: "รหัสผ่านไม่ถูกต้อง",
        });
      }
    },
    async getCustomer() {
      const customerName = $cookies.get("customer");
      const customerRef = db.collection("customer").doc(customerName);

      await customerRef.get().then((cusInfo) => {
        this.customer = cusInfo.data();
        this.prepareAdditionFields();
      });
    },

    prepareAdditionFields() {
      console.log("[[prepareAdditionFields]]");
      const addition_fields = this.customer.addition_fields_report;
      console.log("addition_fields_report=", addition_fields);

      if (!this.isEmpty(addition_fields)) {
        const addition_fields_obj = JSON.parse(addition_fields);

        this.additionFields = addition_fields_obj;
      }

      console.log("[[this.additionFields]]=", this.additionFields);
    },

    getAdditionFieldValue(item) {
      console.log("[[getAdditionFieldValue]] item", item);

      const addition_fields_value = item.addition_fields_value;

      console.log("addition_fields_value=" + addition_fields_value);

      if (this.isEmpty(addition_fields_value)) {
        return item;
      }

      console.log("getAdditionFieldValue 11");

      const addition_fields_value_obj = JSON.parse(addition_fields_value);

      console.log("getAdditionFieldValue 22");

      if (this.isEmpty(addition_fields_value_obj)) return item;
      console.log("getAdditionFieldValue 33");
      console.log("getAdditionFieldValue 44", this.additionFields);

      for (let index = 0; index < this.additionFields.length; index++) {
        const element = this.additionFields[index];
        console.log("element.valueField=" + element.valueField);

        let value = addition_fields_value_obj[element.valueField];
        console.log("value=" + value);

        if (this.isEmpty(value)) {
          item[element.valueField] = "-";
          continue;
        }

        value = value.value;
        console.log("value2=" + value);

        if (this.isEmpty(value)) {
          item[element.valueField] = "-";
          continue;
        }

        item[element.valueField] = value;
      }

      return item;
    },

    openModalExcel() {
      this.startDateReport = null;
      this.endDateReport = null;
      this.activePromptExport = true;
    },

    async onClickDate() {
      if (this.startDateReport) {
        if (this.startDateReport !== this.dateStart) {
          this.dateStart = this.startDateReport;
          this.disabledDates = {
            to: new Date(
              new Date(this.startDateReport - 60 * 60 * 1000) - 8640000
            ),
            from: new Date(
              new Date(this.startDateReport - 60 * 60 * 1000).setDate(
                new Date(this.startDateReport - 60 * 60 * 1000).getDate() + 31
              ) - 8640000
            ),
          };
        }
      }
    },

    exportExcelReport() {
      this.downloadLoading = true;
      this.dbRef()
        .where("date_in", ">=", this.setDateValue("excel")[0])
        .where("date_in", "<", this.setDateValue("excel")[1])
        .orderBy("date_in", "desc")
        .get()
        .then((query) => {
          this.itemsReport = [];
          for (let i = 0; i < query.docs.length; i++) {
            const doc = query.docs[i];
            let date_full = "";
            if (doc.data().date_in && doc.data().date_out) {
              date_full = this.strDate(
                doc.data().date_in.seconds,
                doc.data().date_out.seconds
              );
            }

            let element = {
              key: doc.id,
              addition_remark: doc.data().addition_remark,
              bill_no: doc.data().bill_no,
              car_type: doc.data().car_type,
              card_missing: Vue.filter("cardMissing")(doc.data().card_missing),
              card_mission_amount: doc.data().card_mission_amount,
              close_home_reason: doc.data().close_home_reason,
              contact_address_name: this.isPrefixAddress(
                doc.data().prefix_address,
                doc.data().contact_address_name
              ),
              date_in: Vue.filter("formatDate")(doc.data().date_in),
              date_out: Vue.filter("formatDate")(doc.data().date_out),
              date_full: date_full,
              device_id: doc.data().device_id,
              id_card_photo: doc.data().id_card_photo,
              idcard_photo_name: doc.data().idcard_photo_name,
              is_close_home: Vue.filter("convertCloseHome")(
                doc.data().is_close_home
              ),
              is_vip: Vue.filter("convertVip")(doc.data().is_vip),
              license_plate_id: doc.data().license_plate_id,
              seq: doc.data().seq,
              stamp_type: doc.data().stamp_type,
              sum_amount: doc.data().sum_amount,
              sum_total_time: doc.data().sum_total_time,
              ticket_no: doc.data().ticket_no,
              use_coupon: doc.data().use_coupon,
              visit_objective_type: doc.data().visit_objective_type,
              addition_fields_value: doc.data().addition_fields_value,
            };

            element = this.getAdditionFieldValue(element);
            console.log("element after getAdditionFieldValue", element);

            this.itemsReport.push(element);
          }
          this.downloadLoading = false;
          console.log("======= Report ========");
          console.log("itemsReport.length = " + this.itemsReport.length);
          // console.log(this.itemsReport);
          if (this.itemsReport.length) {
            if (this.additionFields.length > 0) {
              for (let index = 0; index < this.additionFields.length; index++) {
                const element = this.additionFields[index];
                console.log("element.report_field", element.report_field);
                console.log("element.valueField", element.valueField);
                console.log("this.headerTitle.length", this.headerTitle.length);
                console.log("this.headerVal.length", this.headerVal.length);
                this.headerTitle.push(element.report_field);
                this.headerVal.push(element.valueField);
              }
            }

            if (this.customer.enable_fee === false) {
              this.headerTitle = this.headerTitle.filter((res) => {
                return res !== "ค่าจอดรวม";
              });
              this.headerVal = this.headerVal.filter((res) => {
                return res !== "sum_amount";
              });
            }

            console.log("this.headerTitle", this.headerTitle);
            console.log("this.headerVal", this.headerVal);

            import("@/vendor/Export2Excel").then((excel) => {
              const list = this.itemsReport;
              const data = this.formatJson(this.headerVal, list);

              console.log("data=", data);

              excel.export_json_to_excel({
                header: this.headerTitle,
                data,
                filename: this.getExportFileName(),
                autoWidth: true,
                bookType: "xlsx",
              });
            });
            console.log("EXPORT EXCEL");
          } else {
            this.$vs.notify({
              color: "warning",
              title: "Export Excel Report",
              text: "ไม่พบข้อมูล !",
            });
          }
        });
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          const reportValue = v[j];
          if (this.isEmpty(reportValue)) return "-";
          if (Array.isArray(reportValue)) return reportValue.join();

          return reportValue;
        })
      );
    },
  },
  components: {
    Datepicker,
    "v-select": vSelect,
  },
  watch: {},
  async created() {
    this.roleInfo = $cookies.get("roleInfo");
    this.userInfo = $cookies.get("userInfo");
    console.log("[[this.userInfo]]", this.userInfo);

    if ($cookies.get("userInfo") === null) {
      this.$router.push("/pages/login").catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    }
    this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
    const parameters = this.$route.query;

    const home = $cookies.get("home");
    console.log("home", home);

    this.houseInfo = $cookies.get("houseinfo");
    console.log("this.houseInfo=", this.houseInfo);

    await this.getCustomer();
    this.listItems({ page: this.page });
    // this.listItemsAll();
  },
};
</script>

<style>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}
.vs-input {
  width: 100%;
  margin-top: 10px;
}


.demo-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.fullscreen-v-img {
  z-index: 999999999 !important;
}

.svg-icon {
    height: 1em;
    margin-top: -4px;
    pointer-events: none;
    vertical-align: middle;
    width: 1em;
}

</style>
